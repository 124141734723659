export class Util {
  public static checkValidEmailDomain(orgEmailDomain: any, type: string, email: string | undefined): boolean {
    if (!orgEmailDomain || !orgEmailDomain[type]) {
      return true;
    }
    if (!!orgEmailDomain[type] && !email) {
      return false;
    }
    const enteredDoamin = email.split('@').pop();
    return orgEmailDomain[type] === enteredDoamin;
  }

  public static nameObjToString(
    objWithName:
      | {
          title: string | null | undefined;
          firstName: string | null | undefined;
          middleName?: string | null | undefined;
          lastName: string | null | undefined;
        }
      | null
      | undefined
  ): string {
    if (!objWithName) {
      return '';
    }
    const name = `${objWithName.title || ''} ${objWithName.firstName || ''} ${objWithName.middleName || ''} ${
      objWithName.lastName || ''
    }`.trim();
    return name.replace(/\s\s+/g, ' ');
  }
}
