import { Box, Button, Dialog, Grid } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useContext, useState } from 'react';
import { ColumnAttributes, DataTable, DataTableProps } from 'ui-lib';

import { ClientLogger } from 'lib/client-logger';
import { Role, teamMembersQuery, TeamRole } from 'lib/api/api-types';
import { EditTeam } from './EditTeam';
import { TopBackBtnBar } from '../../components/navigation/TopBackBtnBar';
import makeStyles from '@mui/styles/makeStyles';
import { tableClasses } from '../../style/sharedCssClasses';
import { Loading } from 'components/widgets/Loading';
import { TeamMember, useTeamMemberService } from 'lib/api/use-team-member-service';
import { PersonOrgSearch } from 'pages/person-org/PersonOrgSearch';
import { Person } from 'lib/api/use-person-org-serivce';
import { EditTeamMember } from './EditTeamMember';
import { useErrorHandler } from 'lib/use-error-handler';
import { Util } from 'verid-shared-lib';

interface ButtonColumnData {
  teamMember: TeamMember;
}

export interface DisplayRowType extends TeamMember {
  buttons: ButtonColumnData;
}

interface IProps {
  title: string;
  teamId: string;
  orgId: string;
  onClose: () => void;
}
const useClasses = makeStyles({ ...tableClasses });

const DEBUG = false;

export const TeamMemberList = (props: IProps) => {
  const classes = useClasses();
  const teamMemberService = useTeamMemberService();
  const [editMemberOpen, setEditMemberOpen] = useState<false | undefined | string>(false); //false not open, undefined open with new, string teamId beiong edited
  const [editingTeamMember, setEditingTeamMember] = useState<TeamMember | undefined>(undefined);
  const errorHandler = useErrorHandler('TeamMemberList');

  const dataTableOptions: MUIDataTableOptions = {
    pagination: true,
    download: true,
    print: true,
    filter: false,
    search: false,
    viewColumns: false,
    sort: true,
    selectableRows: 'none',
  };
  const columns: ColumnAttributes = {
    id: { display: 'excluded' },
    name: { label: 'Name' },
    description: { display: 'excluded' },
    updatedAt: { display: 'excluded' },
    createdAt: { display: 'excluded' },
    status: { display: 'excluded' },
    title: { display: 'excluded' },
    firstName: { label: 'First Name' },
    middleName: { display: 'excluded' },
    lastName: { label: 'Last Name' },
    orgName: { label: 'Organization' },
    role: { label: 'Role' },
    buttons: {
      label: '',
      customBodyRender: (value: ButtonColumnData) => {
        if (value !== undefined && value !== null) {
          return (
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  DEBUG && ClientLogger.debug('custom render click', '', value);
                  setEditMemberOpen(value.teamMember.id);
                  setEditingTeamMember(value.teamMember);
                }}
              >
                Edit
              </Button>
            </>
          );
        } else {
          return <></>;
        }
      },
      customHeadRender: () => <td key="edit"></td>,
    },
  };

  const transformResponseToRows = (resp: teamMembersQuery | undefined): DisplayRowType[] => {
    if (!resp || !resp.teamMembers || !resp.teamMembers?.edges) {
      return [];
    }
    return resp.teamMembers.edges.map((teamMember) => ({ ...teamMember.node, buttons: { teamMember: teamMember.node } }));
  };

  const tableProps: DataTableProps<string, any, teamMembersQuery, DisplayRowType> = {
    useQuery: teamMemberService.useTeamMemberQuery({ variables: { teamId: props.teamId } }) as any, // https://github.com/tannerlinsley/react-query/issues/1675
    initialQuery: {},
    transformResponseToRows,
    columnAttributes: columns,
    tableAttributes: dataTableOptions,
    emptyMessage: 'No team members found.  Click the + button to add a new team member.',
    onAdd: () => {
      DEBUG && ClientLogger.debug('onAdd', '', {});
      setEditMemberOpen(undefined);
    },
  };

  async function addMember(person: Person, role: TeamRole) {
    const personOrgId = person.personOrgs.find((po) => po.orgId)?.id;
    if (!personOrgId) {
      throw new Error('No personOrgId found for person');
    }
    DEBUG && ClientLogger.debug('addMember', '', { person });
    const resp = await teamMemberService.teamMemberCreate({
      teamId: props.teamId,
      role,
      personOrgId,
    });
    if (resp.errors) {
      errorHandler.handleMutateResponse(resp);
    }
    setEditMemberOpen(false);
  }

  DEBUG && ClientLogger.debug('OrgList', 'render', { tableProps, props });

  return (
    <>
      <Grid container>
        <TopBackBtnBar title={props.title} />
        <Box mx={2} mt={6} mb={1} className={classes.tableHolder}>
          <DataTable {...tableProps} />
        </Box>
      </Grid>
      <Dialog open={!(editMemberOpen === false)} onClose={() => setEditMemberOpen(false)} maxWidth="sm" fullWidth>
        <EditTeamMember
          onClose={() => {
            setEditMemberOpen(false);
          }}
          editingId={editMemberOpen || ''}
          dialogTitle={`Editing ${Util.nameObjToString(editingTeamMember)}`}
        />
      </Dialog>
      <Dialog open={editMemberOpen === undefined} onClose={() => setEditMemberOpen(false)} maxWidth="sm" fullWidth>
        <PersonOrgSearch
          onClose={function (): void {
            setEditMemberOpen(false);
          }}
          onSelect={function (person: Person, role): void {
            addMember(person, role as TeamRole);
          }}
          buttons={[
            { id: 'ADMIN', name: 'Add as Admin' },
            { id: 'STAFF', name: 'Add as Staff' },
          ]}
        />
      </Dialog>
    </>
  );
};
